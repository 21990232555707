// Menu Drawer
const menuDrawerControl = document.getElementById('menu-drawer-control');
if (menuDrawerControl) {
    const menuDrawer = document.getElementById('menu-drawer');
    const menuDrawerCloseControl = document.getElementById('menu-drawer-close-btn');
    const menuDrawerOverlay = document.querySelector('#menu-drawer-overlay');

    const openMenuDrawer = () => {
        setTimeout(() => {
            menuDrawer.classList.add('open')
            menuDrawerOverlay.classList.add('open')
        }, 50);
    };

    const closeMenuDrawer = () => {
        menuDrawer.classList.remove('open');
        menuDrawerOverlay.classList.remove('open');
    };

    menuDrawerControl.addEventListener('click', openMenuDrawer);
    menuDrawerCloseControl.addEventListener('click', closeMenuDrawer);

    menuDrawerOverlay.addEventListener('click', (event) => {
        if (!menuDrawer.contains(event.target)) {
            closeMenuDrawer();
        }
    });
}
